<template>
    <rsck-page padding="0">
        <tab @tab-switch="tabSwitch" :is-show-line="false" :style="{height:pageHeight+'px'}">
            <nut-tab-panel :tab-title="item.label" v-for="(item,index) in catalog" :style="{height:scrollHeight+'px'}" :key="index">
                <rsck-refresh
                        :ref="'refresh_'+index"
                        :id="'refresh_'+index"
                        :specialStyles="{top:tabHeight+'px',overflowY:'scroll'}"
                        @onPullDown="onPullDown"
                        @onPullUp="onPullUp"
                        padding="0"
                        :height="scrollHeight"
                        v-if="showResult"
                >
                    <div class="card-list">
                        <service-card
                                v-for="(card,cardIndex) in dataList[showIndex]"
                                :key="cardIndex"
                                :cover="card.cover_path"
                                :title="card.title"
                                :deduct="card.deduct_renshengquan"
                                :tags="card.tags"
                                :location="card.village_name"
                                :price="card.price"
                                :id="card.id"
                                style="margin-top:10px"
                                @goToGoodsInfo="goToGoodsInfo"
                        />
                    </div>
                </rsck-refresh>
            </nut-tab-panel>
        </tab>
    </rsck-page>
</template>

<script>
  import RsckPage from '@/components/common/rsck-page'
  import RsckRefresh from '@/components/common/rsck-refresh'
  import Tab from '@/components/tab/Tab'
  import ServiceCard from '@/pages/services/components/service-card'

  export default {
    name: 'my-service',
    components: {ServiceCard, Tab, RsckRefresh, RsckPage},
    mounted () {
      document.title = '我的发布'
      this.tabHeight = document.querySelector('.nut-tab-title').clientHeight
      this.scrollHeight = window.innerHeight - this.tabHeight
      let query = this.$route.query
      this.listTypeId = query?.listTypeId || 1
      this.isRefresh = query?.isRefresh || 0
      if (this.isRefresh) {
        this.init()
        return
      }
      this.init()
    },
    data () {
      return {
        catalog: [
          {label: '已上架', value: 1},
          {label: '已下架', value: 3},
          {label: '审核中', value: 0},
          {label: '未通过', value: 2},
        ],
        tabHeight: 0,
        showIndex: 0,
        listTypeId: 1,//类型 1：二手货 2:社区服务,
        isRefresh: 0,
        dataList: [],
        params: [],
        page: [],
        hasMore: [],
        page_size: 10,
        scrollHeight: 0,
        pageHeight: window.innerHeight,
        showResult: false,
        canContinue: true
      }
    },
    methods: {
      tabSwitch (e) {
        this.showIndex = e
        if (this.dataList[e].length == 0 && this.hasMore[e] == true) {
          this.getDataList().then(() => {
            this.endPullUp()
            this.endPullDown()
          })
        }
      },
      onPullUp () {
        //上拉加载
        this.getDataList().then(() => {
          this.endPullUp()
        })
      },
      onPullDown () {
        //下拉刷新
        this.dataList[this.showIndex] = []
        this.page[this.showIndex] = 1
        this.hasMore[this.showIndex] = true
        this.getDataList().then(() => {
          this.endPullDown()
        })
      },
      endPullUp () {
        let refreshName = 'refresh_' + this.showIndex
        this.$refs[refreshName][0].endPullUp(!this.hasMore[this.showIndex])
      },
      endPullDown () {
        let refreshName = 'refresh_' + this.showIndex
        this.$refs[refreshName][0].endPullDown()
      },
      init () {
        this.showIndex = 0
        this.dataList = []
        this.params = []
        this.page = []
        this.hasMore = []
        this.catalog.forEach(item => {
          this.dataList.push([])
          this.params.push(item.value)
          this.page.push(1)
          this.hasMore.push(true)
        })
        this.getDataList().then(() => {
          //延时100秒后，通知页面开始渲染
          setTimeout(() => {
            this.showResult = true
          }, 100)

          //因为是首次加载，所以需要等待刷新组件渲染成功后在进行状态的结束
          setTimeout(() => {
            this.endPullUp()
            this.endPullDown()
          }, 800)
        })
      },
      getDataList (resolve) {
        return new Promise((resolve, reject) => {
          this.$api.services.myService({
            list_type_id: this.listTypeId,
            status: this.params[this.showIndex],
            page: this.page[this.showIndex],
            page_size: this.page_size
          }).then(response => {
            let data = response.data
            if (data.length < this.page_size) {
              this.hasMore[this.showIndex] = false
            }

            //将新的数据渲染进数据列表
            data.forEach(item => {
              item.tags = [item?.class_name] || []
              item.location = item.location || ''
              this.dataList[this.showIndex].push(item)
            })
            //页码加一
            this.page[this.showIndex]++
            this.$forceUpdate()
            resolve()
          }).catch(error => {
            this.$toast.fail(error.message)
            reject(error)
          })
        })
      },
      goToGoodsInfo (data) {
        if (this.canContinue) {
          this.canContinue = false

          let query = {
            ...data,
            listTypeId: this.listTypeId
          }
          query = encodeURIComponent(JSON.stringify(query))
          this.$platform.wxsdk.wxRoute({
            type: 'navigateTo',
            url: '/web/services/goods-info?query=' + query,
          })

          setTimeout(() => {
            this.canContinue = true
          }, 500)
        }

      }
    }
  }
</script>

<style scoped lang="scss">
    /deep/ .nut-tab {
        background: #f6f6f6;
        border: none;
        padding: 0;
    }

    /deep/ .nut-tab-title {
        border: none;
    }

    /deep/ .nut-tab-title {
        border-bottom: #f6f6f6 solid 2px;
    }

    /deep/ .nut-tab-item {
        background: #f6f6f6;
        padding: 0;
        border: none;
    }
</style>