<template>
    <div class="card-container" @click="goToGoodsInfo">
        <div class="cover">
            <img :src="cover"/>
        </div>
        <div class="right-container">
            <div class="title">
                {{title}}
            </div>
            <div class="warning">
                可用人生券抵扣{{deduct}}元
            </div>
            <div class="tags">
                <div class="tag" v-for="(tag,index) in tags">{{tag}}</div>
            </div>
            <div class="locationAndPrice">
                <div class="location">
                    <img v-if="location" src="https://api.mengjingloulan.com/storage/wechat/services/location-3.png"/>
                    {{location}}
                </div>
                <div class="price">
                    <div class="rmb">¥</div>
                    {{price}}元
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'service-card',
    props: {
      id: {
        type: [String, Number],
        default: 0
      },
      cover: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      deduct: {
        type: [String, Number],
        default: 0
      },
      tags: {
        type: Array,
        default: () => {
          return []
        }
      },
      location: {
        type: String,
        default: ''
      },
      price: {
        type: [String, Number],
        default: 0
      }
    },
    methods: {
      goToGoodsInfo () {
        this.$emit('goToGoodsInfo',{id:this.$props.id})
      }
    }
  }
</script>

<style scoped lang="scss">
    .card-container {
        padding: 15px;
        background: #fff;
        box-shadow: 0px 1px 7px 2px rgba(115, 115, 115, 0.05);
        border-radius: 6px;
        display: flex;

        .cover {
            width: 90px;
            height: 90px;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            border-radius: 6px;
            overflow: hidden;
            margin-right: 10px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .right-container {
            flex: 1;

            .title {
                width: 100%;
                font-size: 14px;
                color: #333333;
                font-weight: bold;
                font-family: PingFangSC-Semibold, PingFang SC;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                word-break: break-all;
                margin-right: 3px;
                height: 14px;
                margin-bottom: 10px;
            }

            .warning {
                width: 100%;
                color: #F46A17;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                height: 14px;
                margin-bottom: 10px;
            }

            .tags {
                width: 100%;
                margin-bottom: 10px;
                height: 20px;
                display: flex;

                .tag {
                    border: #939393 solid 1px;
                    padding: 0 7px;
                    height: 20px;
                    line-height: 20px;
                    color: #939393;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-size: 12px;
                }
            }

            .locationAndPrice {
                width: 100%;
                display: flex;

                .location {
                    width: 132px;
                    max-width: 132px;
                    min-width: 132px;
                    font-size: 12px;
                    color: #333333;
                    font-family: PingFangSC-Regular, PingFang SC;
                    display: flex;
                    align-items: center;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    word-break: break-all;

                    img {
                        width: 12px;
                        height: 12px;
                        margin-right: 2px;
                    }
                }

                .price {
                    flex: 1;
                    display: flex;
                    color: #C10000;
                    font-size: 14px;
                    align-items: flex-end;
                    justify-content: flex-end;
                    height: 14px;
                    font-weight: 600;

                    .rmb {
                        font-size: 10px;
                        height: 14px;
                        display: flex;
                        align-items: flex-end;
                        margin-right: 1px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
</style>